// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const URL = 'https://portal.cdt-2730.np.isccloud.io';
const STAGE = 'cdt-2730';

export const environment = {
  production: true, //either true or false
  NG_APP_COGNITO_USERPOOL_ID: 'us-east-2_r247jSJnS',
  NG_APP_COGNITO_CLIENT_ID: '1rv3msuq3k6gm8u96r9i7arg2',
  NG_APP_COGNITO_REGION: 'us-east-2',
  APP_URL: `${URL}`,
  API_URL: `${URL}/v3/api`,
  STAGE: `${STAGE}`,
  SQLAAS_URL: '#sqlaas_url#',
};
